.g-table-evaluations {
  .g-table tbody tr td {
    vertical-align: top !important;
  }
  tr > td:nth-child(4) {
    padding: 31px 0;
    width: 5%;
  }
}
.expander-height-input {
  .g-text-outlined-input-container {
    height: 64px;
    input {
      text-align: center;
    }
  }
}
