.padding-none {
  padding: 0;
}

#academic-offers-table {
  table {
    th:nth-child(1),
    td:nth-child(1) {
      font-weight: normal !important;
    }

    td:nth-child(4) {
      text-align: center !important;
    }

    td:nth-child(5) {
      text-align: center !important;
    }
  }
  @media only screen and (max-width: 500px) {
    table {
      td .ellipsis {
        white-space: nowrap !important;
        width: 100px !important;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
      // Ocultar columna codigo
      th:nth-child(2),
      td:nth-child(2) {
        display: none;
        width: 50%;
      }

      td {
        .badge {
          max-width: 90px;
        }
      }

      th:nth-child(1) {
        text-align: left;
        padding-left: 30px;
      }

      td:nth-child(2) {
        width: 85% !important;
        text-align: left;
        padding-left: 20px;
      }

      th:nth-child(3),
      td:nth-child(3),
      th:nth-child(4),
      td:nth-child(4),
      th:nth-child(5),
      td:nth-child(5) {
        display: none;
      }

      .react-select {
        width: 100%;
      }
    }
  }
}

.icon-select {
  margin-right: 10px !important;
  margin-top: -2px !important;
}

.Borrador {
  background-color: #ffe6a1 !important;
}

.badge.status-badge {
  width: 110px !important;
}

.badge {
  width: 100px;
}

#academic-offers-duplicate-table {
  table {
    th:nth-child(2),
    td:nth-child(2) {
      font-weight: normal !important;
      text-align: left;
    }

    td:nth-child(5) {
      text-align: center !important;
    }

    td:nth-child(6) {
      text-align: center !important;
    }
  }
  @media only screen and (max-width: 500px) {
    table {
      td .ellipsis {
        white-space: nowrap !important;
        width: 100px !important;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      th:nth-child(1),
      td:nth-child(1) {
        width: 25% !important;
        min-width: 25% !important;
        padding-left: 10px;
      }

      // Ocultar columna codigo
      th:nth-child(2),
      td:nth-child(2) {
        width: 50%;
      }

      td:nth-child(2) {
        width: 85% !important;
        text-align: left;
        padding-left: 20px;
      }

      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }

      th:nth-child(4),
      td:nth-child(4) {
        display: none;
      }

      .react-select {
        width: 100%;
      }
    }
  }
}

.radio-button-table {
  width: 20px;
  height: 20px;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid $primary;
  background-color: white;
  border-radius: 50%;
}

.radio-button-table:checked {
  background-color: $primary !important;
  border: 1px solid $primary !important;
  padding: 2px;
}

.text-svg-secondary .g-icon.icon-secondary path {
  fill: var(--secondary) !important;
}
