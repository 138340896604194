.padding-none{
  padding: 0;
}

#version-list-table {
  table {
    margin-bottom: 154px !important;
    // Ocultar columna fecha inicio
    th:nth-child(6),
    td:nth-child(6) {
      text-align: center;
      button {
        min-height: 40px;
      }
    }
  }
  .no-result{
    // top: -154px;
    position: relative;
  }
  @media only screen and (max-width: 500px) {
    table {
      margin-bottom: 154px !important;

      th:nth-child(1) {
        text-align: left;
        padding-left: 30px;
      }

      // Ocultar columna codigo
      th:nth-child(2),
      td:nth-child(2) {
        display: none;
      }

      // Ocultar columna fecha inicio
      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }

      // Ocultar columna fecha inicio
      th:nth-child(4),
      td:nth-child(4) {
        display: none;
      }

      // Ocultar columna fecha inicio
      th:nth-child(6),
      td:nth-child(6) {
        display: none;
      }

      .react-select {
        width: 100%;
        position: relative;
        z-index: 1;
      }
    }
  }

  .react-select{
    width: 80%;
  }

  .react-select .react-select__control--is-focused .react-select__control--menu-is-open {
    border-color: $primary !important;
    box-shadow: 0 0 0 1px $primary !important;
  }
  .react-select .react-select__control--is-focused {
    border-color: $primary !important;
    box-shadow: 0 0 0 1px $primary !important;
  }
  .react-select .react-select__control {
    border-color: $primary;
  }

  .react-select .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-selected{
    background-color: $primary !important;
  }

  .react-select .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-focused{
    background-color: $secondary !important;
  }
}

#curriculum-mention-list-table {
  table {
    margin-bottom: 154px !important;
  }
  .no-result{
    // top: -154px;
    position: relative;
  }
  @media only screen and (max-width: 500px) {
    table {
      margin-bottom: 154px !important;
      // Ocultar columna codigo
      th:nth-child(2),
      td:nth-child(2) {
        display: none;
        width: 50%;
      }

      th:nth-child(1) {
        text-align: left;
        padding-left: 30px;
      }

      td:nth-child(2) {
        width: 85% !important;
        text-align: left;
        padding-left: 20px;
      }

      // Ocultar columna fecha inicio
      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }
      // Ocultar columna fecha inicio
      th:nth-child(5),
      td:nth-child(5) {
        display: none;
      }

      .react-select {
        width: 100%;
      }
    }
  }

  .react-select{
    width: 80%;
  }

  .react-select .react-select__control--is-focused .react-select__control--menu-is-open {
    border-color: $primary !important;
    box-shadow: 0 0 0 1px $primary !important;
  }
  .react-select .react-select__control--is-focused {
    border-color: $primary !important;
    box-shadow: 0 0 0 1px $primary !important;
  }
  .react-select .react-select__control {
    border-color: $primary;
  }

  .react-select .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-selected{
    background-color: $primary !important;
  }

  .react-select .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-focused{
    background-color: $secondary !important;
  }
}

.icon-select {
  margin-right: 10px !important;
  margin-top: -2px !important;
}

.icon-green {
  color: green !important;
}

.modal-status-version {
  max-width: 410px;
}

.m-auto {
  margin: 0 auto;
}
