/* Tabla jornadas */
#schedulesTable {
  // Ocultar campos en responsive
  @media only screen and (max-width: 500px) {
    table {
      // Ocultar columna codigo
      th:nth-child(1),
      td:nth-child(1) {
        display: none;
      }

      // Ocultar columna fecha inicio
      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }

      // Ocultar columna fecha termino
      // th:nth-child(4),
      // td:nth-child(4) {
      //   display: none;
      // }
    }
  }
}
