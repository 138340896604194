.package-card.cardInfo {
  header {
    h6 {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 5px;
    }
    span {
      font-size: 16px;
      font-weight: bold;
      text-transform: none;
    }
  }
  section {
    p {
      font-size: 12px;
      margin-bottom: 17px;
    }
    .row {
      .col:first-child {
        padding-right: 10px;
      }
      .col:last-child {
        padding-left: 10px;
      }
    }
    .occupiedSeats {
      background-color: unset;
    }
  }
  &.main-card {
    header {
      margin-top: 20px;
    }
    section {
      p:first-child {
        margin-top: 50px;
      }
    }
    &.incomplete-card {
      background-color: #fceeed;
    }
    &.complete-card {
      background-color: #f0fced;
    }
  }
}
