.studyPlansSearch {
  .inputContainer {
    width: 100%;
  }

  .toggleSearchMobile {
    border: 1px solid;
    border-radius: 4px;
    padding: 6px 9px;
    height: 27px;
    display: flex;
    align-items: center;

    .chevron {
      margin-left: auto;
      &.rotateIcon {
        transform: rotate(180deg);
      }
    }

    span {
      margin-left: 8px;
    }
  }

  button {
    min-height: 42px !important;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;

    @media (min-width: 768px) {
      margin-top: 28px;
    }
  }
  .submit {
    width: 100%;
  }

  @media (min-width: 768px) {
    form {
      flex-direction: column;
    }
    .inputContainer {
      &:not(:last-child) {
        width: calc(22% - 9px);
        margin-right: 9px;
      }
    }

    .submit {
      width: 12%;

      button {
        width: 100%;
      }
    }
  }
}
