.student-grades-closure {
  .student-status-badge {
    border-radius: 50rem;
    color: $color-dark;
    font-size: 14px;
    padding: 3px 16px;
    text-transform: capitalize;
    max-width: 120px;
    height: 30px;

    &.approved {
      background-color: #cef0da;
    }

    &.failed {
      background-color: #f2c0bd;
    }

    &.pending {
      background-color: #ffe6a1;
    }

    &.calculating {
      background-color: $body-bg;
    }
  }
}

.student-degree-status-badge {
  .badge.status-badge {
    width: 110px !important;
    white-space: initial;
    width: 145px !important;
  }
}
