$table_padding: 20px;

.g-table-container {
  padding: 0 20px;
  padding-bottom: 15px;
  background-color: $white;
  border-radius: 0px 0px $tabs_radius $tabs_radius;

  .g-button.g-add-button {
    padding: 10px 20px;
    margin: 20px 0 !important;

    .icon-btn {
      padding-right: 4px;

      .g-icon {
        width: 10px;
      }
    }

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .g-button.btn.btn-primary:hover {
    box-shadow: none;
  }

  .g-table {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 22px;

    th {
      font-weight: normal;
      padding-bottom: $table_padding;
      padding-top: $table_padding;
      vertical-align: middle;
    }

    td {
      padding-bottom: $table_padding;
      padding-top: $table_padding;
      vertical-align: middle;
    }

    td:nth-child(1) {
      font-weight: bold;
      color: $black;
    }
    td:last-child {
      text-align: end;
    }

    p {
      margin-bottom: 0px;
    }

    .badge {
      width: 80px;
      padding: 7px;
      border-radius: 15px;
      color: $black;
      opacity: 1;
      font-weight: normal;
      font-size: 14px;
    }

    .badge-danger {
      background-color: $light-pink;
    }

    .badge-primary {
      background-color: $light-green;
    }
  }
}

.table-shadow {
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px rgba($color: $body-bg, $alpha: 0.16);
  border: 1px solid $light-silver-b;
  border-radius: 4px;
}

.activity-table {
  input {
    padding-right: 0px !important;
    text-align: center;
  }
  .g-text-outlined-input-container {
    padding: 0 8px;
    text-align: center;
  }
}

.td-build-empty-row {
  position: relative;
  height: 65px;
  .td-btn-action {
    height: 40;
    min-height: 20;
    width: max-content;
    min-height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
