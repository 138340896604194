.modalSubtitle {
  max-width: 410px;
}
.messageError {
  & > span {
    display: none;
  }
}
.fileUpload {
  height: 64px;
  margin-top: -1px;
  & > div {
    height: 100%;
    position: relative;
    & > div {
      height: 100%;
      & > div > div {
        height: 100%;
        border-radius: 4px;
        background-color: #f4f4f4;
      }
      & > div:nth-child(2) {
        display: flex;
        align-items: center;
        button {
          align-self: center;
          margin: auto 0;
        }
      }
    }
  }
  label {
    font-size: 1rem;
    color: #838383;
    position: absolute;
    top: 32%;
    left: 20px;
    z-index: 2;
  }
}

.labelHidden label {
  display: none;
}
.academicOffersCreateStep2Table {
  table {
    tr {
      th,
      td {
        padding-left: 37px;
        font-weight: normal;
      }

      td {
        @media (max-width: 500px) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      th {
        @media (max-width: 500px) {
          padding-left: 16px;
        }
      }
    }
  }
}

.notTitle > div > div > table > thead,
.notTitle tfoot {
  display: none;
}
