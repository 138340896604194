.g-form-container {
  .g-button.g-add-button {
    padding: 10px 20px;
    margin: 20px 0 !important;

    .icon-btn {
      padding-right: 4px;

      .g-icon {
        width: 10px;
      }
    }

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .g-button.btn.btn-primary:hover {
    box-shadow: none;
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

span.g-day-label {
  padding: 20px 0;
  float: left;
  font-size: 16px;
  color: $black;
}

.g-white-space.row {
  margin: 43px 0;
}
