.plusButton {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}
