.studyPlansTable {
  .vigente {
    background-color: #ffe6a1 !important;
  }

  @media only screen and (max-width: 500px) {
    table {
      th:nth-child(1),
      td:nth-child(1),
      th:nth-child(3),
      td:nth-child(3),
      th:nth-child(4),
      td:nth-child(4) {
        display: none;
      }
    }
  }
}
