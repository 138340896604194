.list {
  @media only screen and (max-width: 500px) {
    table {
      th:nth-child(1),
      td:nth-child(1),
      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }
    }
  }
}
