.form {
  .periodName {
    border: 1px solid;
    border-radius: 4px;
    padding: 12px 15px;
    height: 65px;
    span:nth-child(1) {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
