.semesters-list {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  max-width: 1067px;
  width: 100%;
  display: flex;
  flex-direction: row;

  .semester {
    width: 115px;
    display: flex;
    flex-direction: column;
    padding-left: 2px;
    padding-right: 2px;

    .semester-courses {
      flex-grow: 1;
    }

    .title {
      width: 103px;
      text-align: center;
      color: $color-dark;
      float: left;
      margin-bottom: 20px;
    }

    .dashed-border {
      padding: 18px 38px;
      width: fit-content;
      border: 1px dashed #f2f2f2;
      border-radius: 4px;
      float: left;
      margin-bottom: 5px;

      .circle {
        .g-button.btn.btn-primary:hover,
        .g-button.btn.btn-primary:focus {
          box-shadow: none;
        }

        button {
          background-color: transparent;
          height: 25px;
          width: 25px;
          padding: 0px;
          min-height: 0px;
          border: 1px solid $primary;
          border-radius: 50px;
          text-align: center;

          .icon-btn {
            padding: 0px;
          }
          .g-icon path {
            fill: $primary;
          }
        }

        button.read-only {
          border: 1px solid $color-light;
          .g-icon path {
            fill: $color-light;
          }
        }
      }
    }

    .course-box {
      border: 1px solid #f2f2f2;
      float: left;
      border-radius: 4px;
      margin-bottom: 5px;
      max-width: 103px;
      padding: 10px;
      position: relative;
      width: 103px;
      min-height: 75px;

      span.name {
        color: $color-dark;
        margin-bottom: 10px;
        font-size: 12px;
        white-space: normal;
      }
      span {
        float: left;
        font-size: 10px;
        width: 100%;
      }
      span.type {
        max-width: 75%;
        white-space: pre-wrap;
      }
      span.delete {
        max-width: 13px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;

        .trash {
          path {
            fill: $color-light;
          }
        }
      }
    }

    .credits {
      width: 103px;
      text-align: center;
      color: $color-dark;
      background-color: $color-lightest;
      float: left;
      padding-top: 12px;
      padding-bottom: 12px;

      span {
        font-weight: bold;
      }
    }
  }
}

.course-modal {
  .studyplan-info {
    border: 1px solid;
    border-radius: 4px;
    padding: 12px 30px;
  }

  .studyplan-name {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.error-modal {
  .tittle {
    font-size: 22px;
    color: $color-dark;
  }
  .desc {
    font-size: 18px;
    color: $color-light;
  }
}
