.base-doc-container,
.loaded-doc-container,
.upload-doc-container {
  background-color: $color-lightest;
  width: 275px;
  padding: 22px 15px;
  .title {
    color: $primary;
  }
  .btn-outline-primary {
    background-color: white;
    font-weight: bold;
  }
  .btn-outline-primary:hover {
    background-color: $primary;
  }
}

.loaded-doc-container {
  margin: 0 auto;
}

.upload-doc-container {
  margin-left: auto;
}

.loaded-doc-container.disabled,
.base-doc-container.disabled {
  .btn,
  .title,
  p {
    color: #cccccc;
  }

  .g-button.btn-outline-dark .icon-btn path {
    fill: #cccccc;
  }
  .btn-outline-dark {
    border-color: #cccccc;
    background-color: #ffffff;
  }
}

.upload-doc-container.disabled {
  .btn,
  .title,
  p {
    color: #cccccc;
  }

  .g-button.btn-outline-dark .icon-btn path {
    fill: #cccccc;
  }
  .g-button.btn-dark.disabled {
    border-color: #cccccc;
    background-color: #cccccc;
    color: #ffffff;
  }
}

.text-green {
  color: $green;
}
.text-blue {
  color: $info;
}
.text-red {
  color: $red;
}
