$tabs_radius: 5px;

.g-tabs-container {
  background-color: $white;
  border-radius: $tabs_radius;

  .g-tabs {
    border-bottom: none;
    width: 100%;
    background-color: $light-silver;
    border-radius: $tabs_radius $tabs_radius 0px 0px;

    .nav-link {
      background-color: $light-silver;
      color: $dark-silver;
      border-color: transparent;
      width: 20%;
      text-align: center;
      border-radius: 0;
      padding: 10px;

      :hover {
        cursor: pointer;
      }
      h1 {
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .active.nav-link {
      background-color: $white;
      color: $secondary;
      border-radius: $tabs_radius $tabs_radius 0px 0px;
      border-bottom: 3px $primary solid;
      margin-bottom: -3px;

      h1 {
        font-weight: bold;
      }
    }
  }
  .g-tabs-tariff-tuiton {
    border-bottom: none;
    width: 100%;
    background-color: $light-silver;
    border-radius: $tabs_radius $tabs_radius 0px 0px;

    .nav-link {
      background-color: $light-silver;
      color: $dark-silver;
      border-color: transparent;
      width: 33.3%;
      text-align: center;
      border-radius: 0;
      padding: 10px;

      :hover {
        cursor: pointer;
      }
      h1 {
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .active.nav-link {
      background-color: $white;
      color: $secondary;
      border-radius: $tabs_radius $tabs_radius 0px 0px;
      border-bottom: 3px $primary solid;
      margin-bottom: -3px;

      h1 {
        font-weight: bold;
      }
    }
  }
  .g-tabs-admission-process {
    border-bottom: none;
    width: 100%;
    background-color: $light-silver;
    border-radius: $tabs_radius $tabs_radius 0px 0px;
    flex-wrap: nowrap;
    .nav-link {
      background-color: $light-silver;
      color: $dark-silver;
      border-color: transparent;
      flex-grow: 1;
      text-align: center;
      border-radius: 0;
      padding: 10px;

      :hover {
        cursor: pointer;
      }
      h1 {
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .active.nav-link {
      background-color: $white;
      color: $secondary;
      border-radius: $tabs_radius $tabs_radius 0px 0px;
      border-bottom: 3px $primary solid;
      margin-bottom: -3px;

      h1 {
        font-weight: bold;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .g-tabs-admission-process {
      border-bottom: none;
      width: 100%;
      background-color: $light-silver;
      border-radius: $tabs_radius $tabs_radius 0px 0px;

      .nav-link {
        background-color: $light-silver;
        color: $dark-silver;
        border-color: transparent;
        min-width: 50%;
        text-align: center;
        border-radius: 0;
        padding: 10px;

        :hover {
          cursor: pointer;
        }
        h1 {
          font-size: 13px;
          margin-bottom: 0;
        }
      }

      .active.nav-link {
        background-color: $white;
        color: $secondary;
        border-radius: $tabs_radius $tabs_radius 0px 0px;
        border-bottom: 3px $primary solid;
        margin-bottom: 0px;

        h1 {
          font-weight: bold;
        }
      }
    }
  }
}

// para ver en mobile
.g-tabs-container-fix {
  margin-right: 0 !important;

  /* Hide scrollbar for Chrome, Safari and Opera */
  .g-tabs::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
