/* Tabla periodos */
#modalityTable {
  // Ocultar campos en responsive
  @media only screen and (max-width: 500px) {
    table {
      // Ocultar columna codigo
      th:nth-child(1),
      td:nth-child(1) {
        display: none;
        width: 50%;
      }

      th:nth-child(2) {
        text-align: left;
        padding-left: 30px;
      }
      td:nth-child(2) {
        width: 85% !important;
        text-align: left;
        padding-left: 20px;
      }

      td:nth-child(4) {
        width: 15% !important;
      }

      // Ocultar columna fecha inicio
      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }
    }
  }
}
