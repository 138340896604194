#admissionsPlanesList {
  #select-planes-student {
    border: 1px solid;
    border-radius: 4px;
    background-color: white;
    & > div:nth-child(2) {
      border: 0px solid transparent;
      background-color: transparent;
    }
  }
  .g-table-container {
    figure {
      display: flex;
      justify-content: center;
      padding-top: 44px;
      img {
        width: 300px;
      }
    }
    .card-planes-student {
      border: 1px solid;
      border-radius: 4px;
      padding: 16px;
      min-height: 274px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h6 {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        // height: 48px;
      }
      h6 + span {
        font-size: 14px;
        line-height: 12px;
      }
      p {
        display: flex;
        justify-content: space-between;
        margin: 0px;
        color: var(--primary) !important;
      }
      #occupiedSeats {
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 120px;
        padding: 16px;
        background: #f7f7f7;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-self: center;
        justify-self: center;
        h6 {
          display: block;
          text-align: center;
          font-size: 34px;
          font-weight: normal;
          opacity: 1;
        }
        span {
          display: block;
          text-align: center;
          font-size: 12px;
          opacity: 1;
          margin-top: 8px;
          color: var(--primary) !important;
        }
      }
      nav {
        margin: 0 -16px -16px -16px;
        border-top: 1px solid;
        padding: 8px 16px;
        height: 41px;
        a {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: space-between;
          div {
            display: block;
            width: 24px;
            height: 24px;
            mask-image: url('../../assets/svg/next.svg');
            mask-repeat: no-repeat;
            mask-position: center;
          }
        }
      }
    }
  }
}
