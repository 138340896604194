.cardInfo {
  width: 100%;
  border: 1px solid var(--primary);
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  header {
    padding: 16px;
    h6 {
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: var(--primary);
      margin-bottom: 0px;
    }
    span {
      font-size: 14px;
      color: var(--primary);
    }
  }
  section {
    padding: 0px 16px 16px 16px;
    flex-grow: 1;
    p {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 0px;
    }
    .occupiedSeats {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      background: #f7f7f7;
      padding: 16px 0px;
      height: 100%;
      border-radius: 4px;
      h6 {
        display: block;
        text-align: center;
        font-size: 34px;
        font-weight: normal;
        margin-bottom: 0px;
        opacity: 1;
        color: var(--primary);
      }
      span {
        display: block;
        text-align: center;
        font-size: 12px;
        opacity: 1;
        margin-top: 8px;
      }
    }
  }
  nav {
    border-top: 1px solid var(--primary);
    padding: 8px 16px;
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
