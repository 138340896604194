.box-outlined {
  border: 1px solid $secondary;
  border-radius: 8px;
  padding: 15px;
}

.icon-disabled {
  pointer-events: none;
}

.icon-selector:not([disabled]) {
  cursor: pointer;
}

.box-ul-detail {
  padding: 8px 48px;
  border: 1px solid var(--primary);
  min-height: 80px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  li p {
    display: flex;
    justify-content: space-between;
  }
}

.box-app-version {
  font-size: 10px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  background-color: #fff;
  padding: 3px;
  z-index: 9999;
}

// ---------- CARD --------------

.card {
  border: none;
  border-radius: 4px;
}

// Borders

.border-primary {
  border-color: $primary !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}

// font-colors

.text-black {
  color: $black;
}

.long-toast {
  @media (min-width: 768px) {
    min-width: 535px;
  }
}

.default-select {
  & > div:nth-child(2) {
    height: 42px;
    border-color: $secondary;
    color: #1e1e1e;
    font-size: 16px;
    box-shadow: none;

    &:hover,
    &:active {
      border-color: $secondary;
      outline: none;
    }

    & > div {
      span {
        display: none;
      }

      & > div:nth-child(1) {
        svg {
          position: relative;
          left: 17px;
          color: #cccccc;
        }
      }
      & > div:last-child {
        svg {
          color: $secondary !important;
        }
      }
    }
  }
}

.image-square-container {
  background-color: #f4f4f4;
}
.image-square {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: 4px;
  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.outlined-box {
  border: 0.1em solid $secondary !important;
  border-radius: 5px;
}

.student-content {
  border: 0.1em solid $primary !important;
  border-radius: 5px;
}
.no-transition-A {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
  background-color: $primary !important;
}
.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
  background-color: white !important;
  color: #af2d2d !important;
  font-weight: bolder;
}

.no-transition:hover {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
  background-color: white !important;
  color: white !important;
  background-color: #af2d2d !important;
  font-weight: bolder;
}
.no-transitionC {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
  background-color: $primary !important;
  border-color: $primary;
  color: white !important;
  font-weight: bolder;
}

.no-transitionC:hover {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  box-shadow: none !important;
  background-color: white !important;
  color: white !important;
  background-color: $primary !important;
  font-weight: bolder;
  border-color: $primary;
}
.error-message {
  color: $red;
}

svg.icon-disabled {
  cursor: default;

  path {
    fill: lightgray !important;
  }
}

button.anchor-like {
  background: transparent;
  border: 0;
  color: $primary;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: darken($primary, 30%);
  }
}

// El contenedor de los toasts tiene width fijo lo cual evita que podamos agrandarlo segun su contenido o una pantalla en particula
// aca se setea a unset para poder colocar un width en el hijo en lugar del padre
.Toastify__toast-container.Toastify__toast-container--top-center {
  width: unset;
}

.gutters-filters {
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.bg-tertiary {
  background: $tertiary;
}

.border-gray-100 {
  border-color: $color-gray-100;
}

.text-color-dark {
  color: $color-dark;
}

.interim-degrees-table {
  .table-responsive {
    padding-bottom: 200px;
  }
}
