.cantCreateEventsSubtitle {
  max-width: 300px;
}

.cantCreateEventsTitle {
  @media (max-width: 480px) {
    max-width: 196px;
    margin-left: auto;
    margin-right: auto;
  }
}

.eventsTable {
  table {
    th {
      padding-left: 50px;
      font-weight: normal;
    }

    @media only screen and (max-width: 480px) {
      th:nth-child(2),
      td:nth-child(2) {
        display: none;
      }
    }
  }
}
