.expandable-cell-table {
  .g-table-normal {
    .table-responsive {
      border-radius: 4px;
      border: 1px solid $secondary;
      box-shadow: 0px 6px 10px #f5f5f5;

      table {
        overflow: hidden;
        background: #fff;

        th,
        td {
          padding-left: 0;
        }
        thead {
          background: transparent;
          color: $secondary;

          tr th {
            color: $secondary;
            text-align: center;
            height: 45px;
          }
        }
        tbody {
          border-top: 1px solid $secondary !important;

          tr td {
            text-align: center;
            height: 40px;
            min-height: 40px;
            span.badge-primary {
              background-color: $light-green;
            }
          }

          tr {
            &:last-child {
              td {
                position: relative;
                height: 60px;
              }
            }
          }
        }
        input {
          background-color: #f4f4f4 !important;
          max-width: 81px;
          height: 30px;
          border: none;
          padding: 0 3px;

          @media (max-width: 500px) {
            max-width: 75px;
            margin-right: 5px;
          }
        }
      }

      @media only screen and (max-width: 500px) {
        table {
          tr {
            th,
            td {
              padding-left: 16px !important;
            }
          }

          td .ellipsis {
            white-space: nowrap !important;
            width: 100px !important;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }
          th:nth-child(1) {
            text-align: left;
            padding-left: 30px;
          }
          td:nth-child(1) {
            text-align: left !important;
          }
          td:nth-child(2) {
            width: 20px !important;
            text-align: center;
            padding-left: 20px;
          }
          th:nth-child(3),
          td:nth-child(3),
          th:nth-child(4),
          td:nth-child(4),
          th:nth-child(6),
          td:nth-child(6) {
            display: none;
          }
        }
      }
    }
  }
}
